<template>
  <div class="news-wrapper">
    <div class="secondary-heading px-2 pt-1">
      {{ labels.LBL_RECENT_NEWS }}
    </div>

    <div v-if="!loading">
      <div v-if="recentNews.length > 0">
        <div v-for="news in recentNews" :key="news.id" class="recent-news">
          <div @click="gotoNews(news)">
            <div class="recent-news__title">
              {{ news.title }}
            </div>

            <div class="recent-news__date mt-1">
              <icon-base size="15" class="mr-1">
                <icon-calendar />
              </icon-base>

              <div>{{ news.published_at }}</div>
            </div>
          </div>
        </div>

        <div class="btn-text px-2" @click="seeMore">
          {{ labels.LBL_SEE_MORE }}
        </div>
      </div>

      <div v-else class="px-2 pb-2">No news published</div>
    </div>

    <div v-else class="px-2 pb-2">{{ `${labels.LBL_LOADING}...` }}</div>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import IconBase from "@/components/IconBase.vue";
  import IconCalendar from "@/components/icons/IconCalendar.vue";

  export default {
    name: "RecentNews",

    components: {
      IconBase,
      IconCalendar,
    },

    data() {
      return {
        labels,

        params: {
          page: 1,
          limit: 4,
          published: 1,
        },

        recentNews: [],
      };
    },

    computed: {
      ...mapState({ loading: (state) => state.isBusy }),
    },

    created() {
      this.loadRecentNews();
    },

    methods: {
      async loadRecentNews() {
        const {
          data: { data },
        } = await this.$axios.get("/news", { params: this.params });
        this.recentNews = data;
      },

      gotoNews(news) {
        this.$router.push({
          name: "News Detail",
          params: { newsId: news.id, title: news.title },
        });
      },

      seeMore() {
        this.$router.push({ name: "News" });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .news-wrapper {
    background: $background-color;
  }

  .recent-news {
    cursor: pointer;
    padding: 0.75rem 0;

    &__title {
      padding: 0 1rem;
      @include line-clamp(2);
    }

    &__date {
      display: flex;
      align-items: center;
      font-size: 0.85rem;
      color: gray;
      padding: 0 1rem;

      svg {
        margin-top: -3px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }

    &:hover {
      background: white;
    }
  }

  .see-more {
    display: inline-block;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    cursor: pointer;

    transition: all 200ms linear;

    &:hover {
      background: $background-color;
      color: $primary-color;
    }
  }
</style>
