<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbItems" />
    <div class="container default-padding">
      <div class="section-padding">
        <div v-if="newsDetail">
          <el-row :gutter="30">
            <el-col :span="24" :lg="16" class="mb-4">
              <div class="secondary-heading">{{ newsDetail.title }}</div>
              <div class="news__dateAndEditor disabled">
                <div class="date">
                  <icon-base class="mr-1">
                    <icon-calendar />
                  </icon-base>
                  {{ newsDetail.published_at }}
                </div>

                <div class="editor">
                  <icon-base class="mr-1">
                    <icon-edit />
                  </icon-base>
                  {{ newsDetail.editor }}
                </div>
              </div>

              <img
                v-if="newsDetail && newsDetail.news_images.length > 0"
                :src="newsDetail.news_images[0].image_url"
                alt="New's Image"
                class="news__image"
              />

              <div
                class="mt-2 paragraph ql-editor"
                v-if="newsDetail && newsDetail.content"
                v-html="newsDetail.content"
              ></div>
            </el-col>

            <el-col :span="24" :lg="8">
              <recent-news></recent-news>
            </el-col>
          </el-row>
        </div>

        <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { labels } from "@/common";
  import RecentNews from "@/components/RecentNews";
  import IconBase from "@/components/IconBase.vue";
  import IconCalendar from "@/components/icons/IconCalendar.vue";
  import IconEdit from "@/components/icons/IconEdit.vue";
  import BreadCrumb from "@/components/BreadCrumb.vue";

  export default {
    name: "NewsDetail",

    components: {
      RecentNews,
      IconBase,
      IconCalendar,
      IconEdit,
      BreadCrumb,
    },

    props: {
      newsId: { type: [String, Number] },
      title: { type: String, default: "News title" },
    },

    data() {
      return {
        labels,

        newsTitle: this.title,
        newsDetail: null,
      };
    },

    computed: {
      ...mapState({ detail: (state) => state.news.newsDetail }),

      breadcrumbItems() {
        return [
          {
            name: "News",
            path: "/news",
          },
          {
            name: this.detail?.title,
            path: "",
          },
        ];
      },
    },

    watch: {
      newsId: {
        immediate: true,
        handler(nv) {
          this.loadNewsDetail(nv);
        },
      },

      detail: {
        handler(nv) {
          this.setDetail(nv);
        },
      },

      "newsDetail.title": {
        handler(nv) {
          this.newsTitle = nv;
        },
      },
    },

    methods: {
      loadNewsDetail(id) {
        this.$store.dispatch("news/getNewsDetail", id);
      },

      setDetail(detail) {
        this.newsDetail = detail;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .news {
    &__image {
      height: 300px;
      width: 100%;
      overflow: hidden;
    }

    &__dateAndEditor {
      display: flex;
      align-items: center;
      margin-top: auto;
      margin-bottom: 1rem;

      .date {
        margin-right: 2rem;
      }

      .date,
      .editor {
        display: flex;
        align-items: center;

        svg {
          flex-shrink: 0;
          margin-bottom: 2px;
        }
      }
    }
  }
</style>
